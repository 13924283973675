export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {

    window.addEventListener("scroll", function () {
      if (window.scrollY > 100) {
        document.getElementById("navbar_top").classList.add("sticky-top1");
        // $(".header-top").slideUp();
      } else {
        document.getElementById("navbar_top").classList.remove("sticky-top1");
        // $(".header-top").slideDown();
      }
    });

    // JavaScript to be fired on all pages, after page specific JS is fired
    jQuery(".bar-slider").slick({
      arrows: false,
      dots: true,
      fade: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
    });

    $(".menu-item-has-children > a").click(function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        console.log("Mobile only");
        $(this).closest(".menu-item-has-children").toggleClass("active");
        $(this).closest(".menu-item-has-children").find(".sub-menu").slideToggle("slow");
      }
    });

    $(".toogle-menu").click(function (e) {
      e.preventDefault();
      $(this).toggleClass("active");
      $("body").toggleClass("overflow-hidden");
      $(".nav-primary").toggleClass("active");
    });



    // $(".accessibility-close-icon").click(function (e) {
    //   e.preventDefault();
    //   $(".accessibility-control").removeClass("active");
    // });

    // Close accessibility setting popup when clicking Escape key
    $(document).on("keydown", function (event) {
      const key = event.key;
      if (key === "Escape") {
        $(".accessibility-control").removeClass("active");
      }
    });

    // Close accessibility setting popup when clicking outside of it
    $(document).on("click", function (e) {
      if (
        !(
          $(e.target).closest(".sticky-top .accessible-icon").length > 0 ||
          $(e.target).closest(".accessibility-control .popup-inner").length > 0
        )
      ) {
        $(".accessibility-control").removeClass("active");
      }
    });

    // Contrast
    $(".contrast-row input:radio[name=contrast]").change(function (e) {
      e.preventDefault();
      if ($(this).val() == "high-c") {
        $("html").addClass("contrast-higher");
        $("html").removeClass("contrast-inverted");
        setCookie("accessibility_contrast", "contrast-higher", 7, "admin");
      }
      else if ($(this).val() == "inv-c") {
        $("html").addClass("contrast-inverted");
        $("html").removeClass("contrast-higher");
        setCookie("accessibility_contrast", "contrast-inverted", 7, "admin");
      }
      else {
        $("html").removeClass("contrast-inverted");
        $("html").removeClass("contrast-higher");
        setCookie("accessibility_contrast", "", 7, "admin");
      }
    });

    // Text size
    $(".text-size-row input:radio[name=text-size]").change(function (e) {
      e.preventDefault();

      if ($(this).val() == "medium-text") {
        $("html").addClass("text-size-medium");
        $("html").removeClass("text-size-large");
        setCookie("accessibility_size", "text-size-medium", 7, "admin");
      }
      else if ($(this).val() == "large-text") {
        $("html").addClass("text-size-large");
        $("html").removeClass("text-size-medium");
        setCookie("accessibility_size", "text-size-large", 7, "admin");
      }
      else {
        $("html").removeClass("text-size-medium");
        $("html").removeClass("text-size-large");
        setCookie("accessibility_size", "", 7, "admin");
      }
    });

    // Font
    $(".font-row input:radio[name=font-dyslexia]").change(function (e) {
      e.preventDefault();
      if ($(this).val() == "adapted-font") {
        $("html").addClass("font-adapted");
        setCookie("accessibility_font", "font-adapted", 7, "admin");
      }
      else {
        $("html").removeClass("font-adapted");
        setCookie("accessibility_font", "", 7, "admin");
      }
    });

    // Line Spacing
    $(".line-spacing-row input:radio[name=line-spacing]").change(function (e) {
      e.preventDefault();
      if ($(this).val() == "adapted-line") {
        $("html").addClass("line-spacing-adapted");
        setCookie("accessibility_spacing", "line-spacing-adapted", 7, "admin");
      }
      else {
        $("html").removeClass("line-spacing-adapted");
        setCookie("accessibility_spacing", "", 7, "admin");
      }
    });

    // Justification
    $(".justification-row input:radio[name=justification]").change(function (e) {
      // $(".justification-default-btn").click(function (e) {
      e.preventDefault();
      if ($(this).val() == "adapted-just") {
        $("html").addClass("justification-adapted");
        setCookie("accessibility_justification", "justification-adapted", 7, "admin");
      }
      else {
        $("html").removeClass("justification-adapted");
        setCookie("accessibility_justification", "", 7, "admin");
      }
    });
 
    // type js plugin
    // let typeJsText = document.querySelector(".typeJsText");
    // let textArray = typeJsText.dataset.typetext.split("");
    // let counter = 0;

    // let valueToReplace = "|";
    // let brValue = "<br>";
    // textArray = textArray.map((item) =>
    //   item === valueToReplace ? brValue : item
    // );
    // typeJsText.innerHTML = "";

    // function typeJs() {
    //   if (counter < typeJsText.dataset.typetext.length) {
    //     textArray = typeJsText.dataset.typetext.split("");
    //     textArray = textArray.map((item) =>
    //       item === valueToReplace ? brValue : item
    //     );
    //     typeJsText.innerHTML += textArray[counter];
    //     counter++;
    //   } else {
    //     textArray.pop();
    //     typeJsText.innerHTML = textArray.join("");
    //     if (textArray.length == 0) {
    //       counter = 0;
    //     }
    //   }
    // }

    // setInterval(() => {
    //   typeJs();
    // }, 40);


    let typeJsText = document.querySelector(".typeJsText");
    let textArray = [];
    let counter = 0;
    if (typeJsText) {
      textArray = typeJsText.dataset.typetext.split("");

      const valueToReplace = "|";
      const brValue = "<br>";
      textArray = textArray.map((item) => (item === valueToReplace ? brValue : item));
      typeJsText.innerHTML = "";
    }

    function typeJs() {
      if (counter < textArray.length) {
        // Add the next character to the element
        typeJsText.innerHTML += textArray[counter];
        counter++;
      } else {
        // Clear the interval when animation is complete
        clearInterval(typingInterval);
      }
    }

    // Start the typing animation
    const typingInterval = setInterval(typeJs, 40);



  },
};


document.querySelectorAll(".our-post .accordion-button")
  .forEach((button) => {
    button.addEventListener("click", function () {
      // Update all accordion buttons' text based on their aria-expanded state
      document.querySelectorAll(".our-post .accordion-button")
        .forEach((btn) => {
          const isExpanded = btn.getAttribute("aria-expanded") === "true";
          btn.textContent = isExpanded ? "Read less" : "Read more";
        });
    });
  });


document.addEventListener("DOMContentLoaded", () => {
  const counters = document.querySelectorAll(".counter");
  const options = {
    threshold: 0.5, // Trigger when 50% of the element is visible
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const counter = entry.target;
        animateCounter(counter);
        observer.unobserve(counter); // Stop observing after animation
      }
    });
  }, options);

  counters.forEach((counter) => observer.observe(counter));

  function animateCounter(counter) {
    const target = +counter.getAttribute("data-target");
    const increment = target / 200; // Adjust the increment speed
    let current = 0;

    const updateCounter = () => {
      current += increment;
      if (current < target) {
        counter.textContent = Math.ceil(current).toLocaleString(); // Format with commas
        requestAnimationFrame(updateCounter);
      } else {
        counter.textContent = target.toLocaleString(); // Ensure it ends exactly at the target
      }
    };

    updateCounter();
  }
});


// Create new Cookies function
function setCookie(Cookiename, Cookievalue, exdays, Cookiepath) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toGMTString();
  // document.cookie = Cookiename + "=" + Cookievalue + "; " + expires + path + "/" + Cookiepath;
  document.cookie =
    Cookiename + "=" + Cookievalue + "; " + expires + "; path=/";
}
// get Cookies function
function getCookie(Cookiename) {
  var name = Cookiename + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
