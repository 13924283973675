// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
// import Lenis from '.././../../node_modules/lenis/dist/lenis'
import slick from './slick/slick.min';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // Slick
  slick,
  // Lenis
  // Lenis,
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

document.addEventListener('DOMContentLoaded', () => {
  // Animated On scroll
  // const myAnimatedEl = [...document.querySelectorAll(".el-animate")];
  const myAnimatedEl = document.querySelectorAll(".el-animate");
  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animated");
      }

      if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
        entry.target.classList.remove("animated");
      }
    });
  };
  const observer = new IntersectionObserver(callback);
  myAnimatedEl.forEach((box) => observer.observe(box));
  /*
  myAnimatedEl.forEach((boxs) => {
    console.log(boxs);    
    boxs.forEach((box) => {
      observer.observe(box);
    });
  });*/

});
